import React from "react"
import { Container } from "react-bootstrap"

const PrivacyPolicy = () => {
  return (
    <Container className="py-5">
      <div className="text-center">
        PRIVACY POLICY
        <br />
        <br />
        Effective Date: January 1, 2020
        <br />
        <br />
        To review material modifications and their effective dates scroll to the
        bottom of the page.
        </div>
        <br />
        <br />
        Codev Media ("Codev Media") owns and operates this codevmedia.com
        website business. All references to "we", "us", this "website" or this
        "site" shall be construed to mean Codev Media.
        <br />
        <br />
        HOW WE MODIFY THIS PRIVACY POLICY
        <br />
        <br />
        We reserve the right to modify this Privacy Policy at any time, and
        without prior notice, by posting an amended Privacy Policy that is
        always accessible by clicking on the "Privacy Policy" link on this
        site's home page. Your continued use of this site indicates your
        acceptance of the amended Privacy Policy. <br />
        <br />
        Regarding Personal Information (defined below), if any modifications are
        materially less restrictive on our use or disclosure of the Personal
        Information previously disclosed by you, we will obtain your consent
        before implementing such revisions with respect to such information.
        <br />
        <br />
        THE TYPES OF INFORMATION WE COLLECT
        <br />
        <br />
        Personal Information. "Personal Information" includes any information
        regarding a natural person that may be used directly to identify the
        person. Personal Information that we collect may vary with each separate
        purpose for which you provide it, and it may include one or more of the
        following categories: name, physical address, an email address, phone
        number, credit card information including credit card number, expiration
        date, and billing address, and location data.
        <br />
        <br />
        Usage Data. We reserve the right to collect information based on your
        usage of this site which is information collected automatically from
        this site (or third party services employed in this site), some of which
        may include: the IP addresses or domain names of the computers utilized
        by the users who use this site,the country of origin, the features of
        the browser and the operating system utilized by the user, the various
        time details per visit (e.g., the time spent on each page within the
        site) and the details about the path followed within the site with
        special reference to the sequence of pages visited, marketing and
        conversion data and statistics, reports, analytics data, reviews and
        surveys ("Usage Data"). Usage Data is essentially anonymous when
        collected; however, it could be used indirectly to identify a person.
        <br />
        <br />
        HOW AND WHEN WE COLLECT INFORMATION
        <br />
        <br />
        Personal Information. We collect Personal Information at the time you
        provide it to us through sign-up forms and as part of your registration
        for an account, product, or service, or promotion from this website.
        Personal Information may vary with the each sign-up or registration.
        <br />
        <br />
        Your Communications with Us. We collect Personal Information that we
        receive from you as you communicate with us, for example through our
        eemail services and/or autoresponder service.
        <br />
        <br />
        Usage Data. We reserve the right to monitor your use of this site. As
        you navigate through this site, Usage Data may be passively collected
        (that is, gathered without your actively providing the information)
        using various analytics and reporting technologies, such as cookies and
        web beacons.
        <br />
        <br />
        HOW WE USE YOUR INFORMATION
        <br />
        <br />
        We may use your Personal Information for the performance of the services
        or transaction for which it was given, and in connection with other
        products, services, promotions, or contests we may offer, and our
        private, internal reporting for this site, and security assessments for
        this site.
        <br />
        <br />
        We reserve the right to make full use of Usage Data. For example, we may
        use Usage Data to provide better service to site visitors, customize the
        site based on your preferences, compile and analyze statistics and
        trends about the use of this site, and otherwise administer and improve
        this site for your use. Specific uses are described below.
        <br />
        <br />
        INFORMATION SHARING AND DISCLOSURE
        <br />
        <br />
        General Disclosure Policy. We reserve the right to disclose your
        Personal Information as described below. We reserve the right to
        disclose Usage Data without restriction.
        <br />
        <br />
        Affiliated Entities. We reserve the right to provide your Personal
        Information and Usage Data to any affiliated entities we may have,
        including our subsidiaries. Affiliated entities are entities that we
        legally control (by voting rights) or that control us.
        <br />
        <br />
        Service Providers. We reserve the right to provide access to your
        Personal Information and Usage Data to our trusted service providers
        that assist us with the operation and maintenance of this site. For
        example, we may contract with third parties to process payments, host
        our servers, provide security, and provide production, fulfillment,
        optimization, analytics, and reporting services. Our service providers
        will be given access to your information only as is reasonably necessary
        to provide the services for which they are contracted.
        <br />
        <br />
        Successors. If we sell or otherwise transfer part or all of our business
        or assets to another organization, such as in the course of an
        acquisition, merger, bankruptcy or liquidation, we may transfer your
        Personal Information and Usage Data. In such an event, we will require
        the buyer or transferee to agree to our commitments provided in this
        Privacy Policy.
        <br />
        <br />
        Legal Process, Enforcement and Security Notice. We reserve the right to
        disclose your Personal Information and Usage Data if we have a good
        faith belief that access, use, preservation or disclosure of such
        information is reasonably necessary (i) to satisfy any applicable law,
        regulation, legal process or enforceable governmental request (such as
        for example, to comply with a subpoena or court order), (ii) to detect,
        prevent, and address fraud or other illegal activity, and (iii) to
        investigate, respond to, or enforce violations of our rights or the
        security of this site.
        <br />
        <br />
        Your California Privacy Rights. Under California Law SB 27, California
        residents have the right to receive, once a year, information about
        third parties with whom we have shared information about you or your
        family for their marketing purposes during the previous calendar year,
        and a description of the categories of personal information shared. To
        make such a request, please send an email to the email address provided
        in our contact information below and include the phrase "California
        Privacy Request" in the subject line, the domain name of the Web site
        you are inquiring about, along with your name, address and email
        address. We will respond to you within thirty days of receiving such a
        request.
        <br />
        <br />
        SPECIFIC INFORMATION ABOUT COOKIES AND WEB BEACONS
        <br />
        <br />
        In order to provide better service for our site, we may use Cookies and
        Web Beacons to collect Usage Data to store your preferences and
        information about what pages you visit and past activity at our site. We
        may also employ Web Beacons from third parties in order to help us
        compile aggregated statistics regarding the effectiveness of our
        promotional campaigns or other operations of our site.
        <br />
        <br />
        "Cookies" are tiny pieces of information stored by your browser on your
        computer's hard drive. Cookies are also used to customize content based
        on your browser. Most browsers are initially set to accept cookies. If
        you want to disable cookies, there is a simple procedure in most
        browsers that allows you to turn off cookies. Please remember, however,
        that cookies may be required to allow you to use certain features of our
        site. <br />
        <br />
        Flash Cookies - third party cookies that use an Adobe Flash Media Player
        local shared object (LSO) - may be used along with other third party
        cookies for purposes of crediting any purchase you may make on this site
        to one of our joint venture marketing partners that may have referred
        you to us. These cookies will be used for purposes of crediting sales to
        the referring joint venture marketing partner. Flash cookies are not the
        same as "browser cookies". The Adobe Flash Media Player is software that
        enables users to view content on their computers. Flash cookies are also
        accompanied by a browser cookie. If you delete the browser cookie, the
        Flash cookie may automatically create (or re-spawn) a replacement for
        the browser cookie.
        <br />
        <br />
        Web Beacons - sometimes called single-pixel gifs or clear gifs - are
        used to assist in delivering cookies, and they allow us to count users
        who have visited pages of our site. We may include Web Beacons in
        promotional e-mail messages or other communications in order to
        determine whether messages have been opened and acted upon.
        <br />
        <br />
        ANALYTICS
        <br />
        <br />
        We reserve the right to participate with third party analytics partners
        to monitor and analyze Web traffic and can be used to keep track of user
        behavior on this site.
        <br />
        <br />
        Google Analytics (Google) - Google Analytics is a web analysis service
        provided by Google Inc. ("Google"). Google utilizes the data collected
        to track and examine the use of this site, to prepare reports on its
        activities, and to share them with other Google services. Information
        collected: cookie and Usage Data. Visit the Google Privacy Policy at
        https://www.google.com/intl/en/policies/?fg=1 You may optout of the
        Google Analytics service with the Google's Browser Add-on that's
        available at https://tools.google.com/dlpage/gaoptout/
        <br />
        <br />
        SOCIAL MEDIA INTERACTIONS
        <br />
        <br />
        We invite you to socialize and share your participation with this site
        and purchases. If you choose to use social media platforms such as
        Facebook, Twitter, Pinterest, and Instagram, you will be allowing
        interaction with these platforms or other external platforms directly
        from this site, and in the process you may be sharing certain profile
        elements, including your comments. This sharing is subject to each
        social media program's privacy policies. <br />
        <br />
        DO NOT TRACK REQUESTS
        <br />
        <br />
        Some Web browsers incorporate a "Do Not Track" feature that signals to
        websites that you visit that you do not want to have your online
        activity tracked. Each browser communicates "Do Not Track" signals to
        websites differently, making it unworkable to honor each and every
        request correctly. In order to alleviate any communication error between
        browsers and website, we do not respond to "Do Not Track" signals at
        this time. As the technology and communication between browser and
        website improves, we will reevaluate the ability to honor "Do Not Track"
        signals and may make changes to our policy.
        <br />
        <br />
        DATA SECURITY
        <br />
        <br />
        We will implement reasonable and appropriate security procedures
        consistent with prevailing industry standards to protect data from
        unauthorized access by physical and electronic intrusion. Unfortunately,
        no data transmission over the Internet or method of data storage can be
        guaranteed 100% secure. Therefore, while we strive to protect your
        Personal Information by following generally accepted industry standards,
        we cannot ensure or warrant the absolute security of any information you
        transmit to us or archive at this site.
        <br />
        <br />
        When you transmit Personal Information through our registration process
        or if you purchase products or services, we encrypt that information in
        transit using secure socket layer technology (SSL).
        <br />
        <br />
        After the secure transfer of your Personal Information, the information
        is maintained and stored with 128-bit encryption.
        <br />
        <br />
        ONWARD TRANSFER OUTSIDE YOUR COUNTRY OF RESIDENCE
        <br />
        <br />
        Any Personal Information which we may collect on this site may be stored
        and processed in our servers located in the United States or in any
        other country in which we, or our affiliates, subsidiaries, or agents
        maintain facilities. By using this site, you consent to any such
        transfer of Personal Information outside your country of residence to
        any such location.
        <br />
        <br />
        UPDATING PERSONAL INFORMATION
        <br />
        <br />
        Upon request, we will permit you to request or make changes or updates
        to your Personal Information for legitimate purposes. We request
        identification prior to approving such requests. We reserve the right to
        decline any requests that are unreasonably repetitive or systematic,
        require unreasonable time or effort of our technical or administrative
        personnel, or undermine the privacy rights of others. We reserve the
        right to permit you to access your Personal Information in any account
        you establish with this site for purposes of making your own changes or
        updates, and in such case, instructions for making such changes or
        updates will be provided where necessary.
        <br />
        <br />
        LINKS TO JOINT VENTURE MARKETING PARTNER SITES
        <br />
        <br />
        This site may contain links to other websites operated by our joint
        venture marketing partners. If you do click on any of the links to their
        websites or accept any of their promotional offers, your click-through
        information and any information that you provide in the process of
        registration or purchase will be transferred to these sites. We have no
        responsibility or liability for the policies and practices of these
        sites. You should be careful to review any privacy policies posted on
        any of these sites before providing information to them.
        <br />
        <br />
        CHILDREN'S ONLINE POLICY
        <br />
        <br />
        We are committed to preserving online privacy for all of its website
        visitors, including children. This site is a general audience site.
        Consistent with the Children's Online Privacy Protection Act (COPPA), we
        will not knowingly collect any information from, or sell to, children
        under the age of 13. If you are a parent or guardian who has discovered
        that your child under the age of 13 has submitted his or her personally
        identifiable information without your permission or consent, we will
        remove the information from our active list, at your request. To request
        the removal of your child's information, please contact our site as
        provided below under "Contact Us", and be sure to include in your
        message the same login information that your child submitted.
        <br />
        <br />
        CONTACT US
        <br />
        <br />
        If you have any questions regarding this Privacy Policy, please contact
        the owner and operator of this website business at:
        <br />
        <br />
        <span style={{ paddingLeft: "20px" }} />{" "}
        <span style={{ paddingLeft: "20px" }} /> Codev Media
        <br />
        <span style={{ paddingLeft: "20px" }} />{" "}
        <span style={{ paddingLeft: "20px" }} /> Attn: Privacy Policy Officer
        <br />
        <span style={{ paddingLeft: "20px" }} />{" "}
        <span style={{ paddingLeft: "20px" }} /> Oklahoma City, Oklahoma, 73162
        <br />
        <span style={{ paddingLeft: "20px" }} />{" "}
        <span style={{ paddingLeft: "20px" }} /> Email: info@codevmedia.com
        <br />
        <span style={{ paddingLeft: "20px" }} />{" "}
        <span style={{ paddingLeft: "20px" }} /> Telephone: (405)509-9218
        <br />
        <br />
        --
        <br />
        <br />
        »Material Modifications« Since January 1, 2020: none.
        <br />
        <br />
        Notice: This document is Copyright © Chip Cooper of the law firm of
        Jones &amp; Haley, P.C., and licensed for use by the owner of this
        website under distribution rights granted to FTCGuardian.com. All rights
        reserved worldwide. No part of this document may be copied, reprinted,
        reproduced, or transmitted in any form or by any means without the prior
        written permission of the copyright owner.
        <br />
        <br />
      <br />
      <hr />
      <h3>Document Modification</h3>
      <p>
        We do permit user modifications. You are reminded that your
        modifications could change desired legal results, and for this reason,
        it's recommended that you consult with a qualified attorney.
      </p>
    </Container>
  )
}

export default PrivacyPolicy
