import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import HeroInner from "../components/heroinner"
import PrivacyPolicy from "../components/privacypolicy"

const Privacy = () => (
  <Layout>
    <SEO title="Privacy Policy" description="Codev Media Privacy Policy"/>
    <HeroInner title="Privacy Policy" />
    <PrivacyPolicy />
  </Layout>
)

export default Privacy;